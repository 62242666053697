"use client";

import { GE } from ".constants/gtmEvents";
import { Button, InfoBoxV4, usePortalContext } from "@cloai/uikit";
import { signIn } from "next-auth/webauthn";
import { useTranslations } from "next-intl";
import { FC, ReactNode } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import { useGTMContext, useProfileContext } from "@/providers";
import OnePassWhite from "@/styles/image/social-buttons/passkey-white.svg";

type HeaderGiftButtonVariant = "burger" | "header";

export const PasskeyButton: FC<{ variant: HeaderGiftButtonVariant }> = ({
  variant,
}) => {
  const t = useTranslations("PasskeyModal");
  const profile = useProfileContext();
  const { closePortal } = usePortalContext();
  const { sendGTM } = useGTMContext();

  const [, setCookie] = useCookies(["passkeyDontShow"]);

  if (!profile) {
    return null;
  }

  const onClickConfirm = async () => {
    try {
      await signIn("passkey", { action: "register" });
      sendGTM({ event: GE.AUTH_VIA_PASSKEY__REGISTER });
      toast.success(t("toastSuccess"));
      setCookie("passkeyDontShow", 1);
      closePortal();
    } catch (err) {
      const error = err as Error;
      toast.error(error.message);
    }
  };

  const variants: Record<HeaderGiftButtonVariant, ReactNode> = {
    header: (
      <InfoBoxV4
        iconLeft={OnePassWhite}
        color="violet"
        onClick={onClickConfirm}
      />
    ),
    burger: (
      <Button
        iconLeft={OnePassWhite}
        text={t("confirm")}
        color="outline"
        centerText
        wide
        onClick={onClickConfirm}
        className="mb-3"
      />
    ),
  };

  return variants[variant];
};
