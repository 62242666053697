import { PORTALS } from ".constants/portals";
import {
  usePortalContext,
  Text,
  PortalModal,
  Button,
  Switch,
  ModalContent,
} from "@cloai/uikit";
import { filter, isEmpty, map } from "lodash";
import { useTranslations } from "next-intl";
import { CSSProperties, FC, useCallback, useMemo, useState } from "react";

import {
  DefaultNotification,
  PaymentNotification,
  PortalNotification,
} from "@/.gql/graphql";
import { SidebarHeader } from "@/components/sidebar/SidebarHeader";

import { NotificationsItem } from "./NotificationsItem";
import { useNotifications } from "./useNotifications";

export const NotificationsSidebar: FC = () => {
  const t = useTranslations("Notifications");
  const { closePortal } = usePortalContext();

  /**
   * Хук для получения данных об уведомлениях и обработчиков:
   * - toastNotifications, portalNotifications — списки уведомлений для разных типов
   * - unreadNotifications — непрочитанные уведомления
   * - onRead — функция для отметки уведомления как «прочитанное»
   * - closeCurrentPortal — закрыть активное модальное окно (если нужно, пометить как прочитанное)
   * - loading — статус загрузки
   * - onOpenDetails — открыть детальную карточку уведомления (через портал)
   */
  const {
    toastNotifications,
    portalNotifications,
    unreadNotificationsIds,
    unreadCount,
    onRead,
    loading,
    onOpenDetails,
  } = useNotifications();

  /**
   * Локальное состояние: переключатель вкладок (System / News)
   * 0 - System, 1 - News
   */
  const [showTab, setShowTab] = useState(0);

  /**
   * Генератор функции, позволяющей переключить вкладку (System / News).
   * @param index Индекс вкладки (0 или 1)
   */
  const onSetTab = useCallback((index: number) => () => setShowTab(index), []);

  /**
   * Флаг наличия непрочитанных уведомлений
   */
  const hasUnreaded = unreadCount > 0;

  /**
   * Локальное состояние: фильтрация уведомлений (показ только непрочитанных)
   */
  const [isFiltered, setIsFiltered] = useState(false);

  /**
   * Переключатель фильтрации уведомлений
   */
  const onToggleFilter = useCallback(() => {
    setIsFiltered((prev) => !prev);
  }, []);

  /**
   * Фильтрует уведомления типа toast, если включен фильтр
   */
  const filteredToastNotifications = useMemo(() => {
    if (isFiltered) {
      return filter(toastNotifications, { readAt: null });
    }

    return toastNotifications;
  }, [isFiltered, toastNotifications]);

  /**
   * Обёртка для открытия деталей портального уведомления,
   * предварительно закрывает выпадающий список.
   *
   * @param id - ID уведомления для открытия детализации.
   */
  const handleOpenDetails = useCallback(
    (id: string) => () => {
      closePortal();
      onOpenDetails(id);
    },
    [closePortal, onOpenDetails],
  );

  return (
    <PortalModal
      portalId={PORTALS.NotificationSidebar}
      variant="right"
      style={
        {
          "--color-popup": "var(--color-body)",
        } as CSSProperties
      }
    >
      <SidebarHeader />
      {/**
       * Заголовок списка уведомлений
       */}
      <Text text={t("title")} fontSize={16} fontWeight={700} center />

      {/**
       * Вкладки для переключения между типами уведомлений (System / News)
       */}
      <div className="flex">
        <Text
          text={t("system")}
          fontSize={16}
          fontWeight={500}
          color={showTab === 0 ? "orange" : "white"}
          center
          onClick={onSetTab(0)}
          style={{ cursor: "pointer" }}
          aria-label="Tab System"
        />
        <Text
          text={t("news")}
          fontSize={16}
          fontWeight={500}
          color={showTab === 1 ? "orange" : "white"}
          center
          onClick={onSetTab(1)}
          style={{ cursor: "pointer" }}
          aria-label="Tab News"
        />
      </div>

      <hr />

      {/**
       * Вкладка "System"
       */}
      {showTab === 0 && (
        <>
          {/**
           * Панель управления: фильтр и кнопка "Read all"
           */}
          <div className="flex items-center justify-between">
            {/**
             * Фильтр для отображения только непрочитанных уведомлений
             */}
            <div
              className="flex cursor-pointer items-center gap-2"
              onClick={onToggleFilter}
              aria-label="Unread notifications filter"
            >
              <Text
                text={t("unread")}
                fontSize={14}
                fontWeight={500}
                color="gray"
              />
              <Switch
                checked={isFiltered}
                onClick={onToggleFilter}
                size="small"
                aria-label="Filter switch"
              />
            </div>

            {/**
             * Кнопка "Read all" для отметки всех уведомлений как прочитанных
             */}
            <Button
              iconLeft={loading ? "loading" : ""}
              text={t("readAll")}
              color="regular"
              size="tiny"
              onClick={onRead(unreadNotificationsIds)}
              loading={loading}
              disabled={!hasUnreaded}
              aria-label="Mark all as read"
            />
          </div>

          {/**
           * Список уведомлений типа "toast"
           */}
          <ModalContent className="!gap-1">
            {isEmpty(filteredToastNotifications) ? (
              <Text
                text={t("noNotifications")}
                fontSize={16}
                fontWeight={500}
                color="notice"
                center
                aria-label="No notifications yet"
              />
            ) : (
              map(filteredToastNotifications, (item) => {
                const props = item.props as
                  | DefaultNotification
                  | PaymentNotification;

                if (props.__typename === "PaymentNotification") {
                  return (
                    <NotificationsItem
                      key={item.id}
                      title={t(`toastBalance.${props.title}` as never)}
                      text={t.rich("toastBalance.text", {
                        coins: props.coins,
                        type: props.typeCoins,
                      })}
                      createdAt={item.createdAt}
                      readed={!!item.readAt}
                      onRead={onRead([item.id])}
                    />
                  );
                }

                return (
                  <NotificationsItem
                    key={item.id}
                    title={props?.title}
                    text={props.text}
                    createdAt={item.createdAt}
                    readed={!!item.readAt}
                    onRead={onRead([item.id])}
                  />
                );
              })
            )}
          </ModalContent>
        </>
      )}

      {/**
       * Вкладка "News"
       */}
      {showTab === 1 && (
        <ModalContent className="!gap-1">
          {isEmpty(portalNotifications) ? (
            <Text
              text={t("noNotifications")}
              fontSize={16}
              fontWeight={500}
              color="notice"
              center
              aria-label="No notifications yet"
            />
          ) : (
            map(portalNotifications, (item) => {
              const props = item.props as PortalNotification;
              return (
                <NotificationsItem
                  key={item.id}
                  title={props?.title}
                  createdAt={item.createdAt}
                  readed={!!item.readAt}
                  // При клике откроется портал с деталями
                  onClick={handleOpenDetails(`notification-${item.id}`)}
                />
              );
            })
          )}
        </ModalContent>
      )}
    </PortalModal>
  );
};
