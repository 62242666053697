import { Text } from "@cloai/uikit";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { useLocale } from "next-intl";
import { FC, memo, ReactNode } from "react";

import { cn } from "@/utils/cn";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

type TNotificationsItemProps = {
  title?: string;
  text?: string | ReactNode;
  createdAt: Date;
  readed?: boolean;
  onClick?: () => void;
  onRead?: () => void;
};

/**
 * Компонент для отображения одного уведомления в списке.
 *
 * @remarks
 * - Использует стили, позволяющие визуально показывать, что уведомление прочитано (прозрачность)
 *   либо подчеркивает возможность клика (курсор, обводка при наведении), если задан обработчик onClick.
 * - Если уведомление непрочитано, при наведении мыши вызывается проп onRead (например, для отметки уведомления как прочитанного).
 *
 * @property {string} [title] - Заголовок уведомления, выводится, если он не пустой.
 * @property {string | ReactNode} [text] - Текст уведомления (может быть строкой или JSX-элементом).
 *                                         Если не указан, блок текста не отображается.
 * @property {Date} [createdAt] - Время создания уведомления.
 * @property {boolean} [readed] - Признак, что уведомление прочитано. Если true, то элемент получает класс "opacity-42".
 * @property {() => void} [onClick] - Обработчик клика по всей зоне уведомления.
 * @property {() => void} [onRead] - Обработчик при наведении (onMouseEnter). Удобно для логики «автоматически пометить как прочитанное».
 *
 * @example
 * ```tsx
 * import { NotificationsItem } from "./NotificationsItem";
 *
 * function Example() {
 *   return (
 *     <NotificationsItem
 *       title="Новое сообщение"
 *       text="Привет! У нас для тебя кое-что новенькое..."
 *       readed={false}
 *       onClick={() => console.log('Открыть уведомление')}
 *       onRead={() => console.log('Пометить как прочитанное')}
 *     />
 *   );
 * }
 * ```
 */

export const NotificationsItem: FC<TNotificationsItemProps> = memo(
  ({ title, text, createdAt, readed, onClick, onRead, ...props }) => {
    const locale = useLocale();

    return (
      <div
        className={cn(
          "relative rounded-xl bg-regular p-3 transition-all duration-300",
          readed && "opacity-42",
          onClick &&
            "cursor-pointer hover:inset-ring-2 hover:inset-ring-regular-foreground/10 active:scale-95",
        )}
        {...props}
        onClick={onClick}
        onMouseEnter={!readed ? onRead : undefined}
      >
        <div className="flex flex-col gap-2">
          {title && (
            <Text
              text={title}
              color="white"
              fontSize={16}
              fontWeight={700}
              lineHeight={140}
            />
          )}

          {text && (
            <Text text={text} color="gray" fontSize={16} lineHeight={140} />
          )}
        </div>

        <div className="ml-auto text-end text-xs/3 font-medium text-foreground/30">
          {dayjs(createdAt).locale(locale).fromNow()}
        </div>
      </div>
    );
  },
);

NotificationsItem.displayName = "NotificationsItem";
