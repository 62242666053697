"use client";

import { InfoBoxV4 } from "@cloai/uikit";
import { Suspense } from "react";

import { NotificationsProvider, useProfileContext } from "@/providers";
import { cn } from "@/utils/cn";

import { NotificationsSidebar } from "./NotificationsSidebar";
import { NotificationsTrigger } from "./NotificationsTrigger";

export type NotificationsVariant = "header" | "burger";

type NotificationsProps = {
  variant: NotificationsVariant;
};

export const Notifications = ({ variant }: NotificationsProps) => {
  const profile = useProfileContext();

  if (!profile) {
    return null;
  }

  return (
    <Suspense
      fallback={
        <div className={cn("hide", "md:block")}>
          <InfoBoxV4 iconLeft="loading" color="violet" loading />
        </div>
      }
    >
      <NotificationsProvider>
        <NotificationsTrigger variant={variant} />
        <NotificationsSidebar />
      </NotificationsProvider>
    </Suspense>
  );
};
