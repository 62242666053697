import { Button, ModalHeader, usePortalContext } from "@cloai/uikit";
import { FC } from "react";

import { HeaderLogo } from "@/components/header/HeaderLogo";

export const SidebarHeader: FC<{ promo?: boolean }> = ({ promo }) => {
  const { closePortal } = usePortalContext();

  return (
    <ModalHeader>
      <div className="flex w-full items-center justify-between">
        <HeaderLogo promo={promo} />

        <Button
          iconLeft="close"
          color="none"
          className="!text-secondary"
          authType
          onClick={closePortal}
        />
      </div>
    </ModalHeader>
  );
};
