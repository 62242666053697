import { PORTALS } from ".constants/portals";
import { InfoBoxV4, usePortalContext, Text, Badge } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC } from "react";

import { cn } from "@/utils/cn";

import { NotificationsVariant } from ".";
import { NotificationsPortal } from "./NotificationsPortal";
import { useNotifications } from "./useNotifications";

type TNotificationsTriggerProps = {
  variant: NotificationsVariant;
  onClick?: () => void;
};

export const NotificationsTrigger: FC<TNotificationsTriggerProps> = ({
  variant,
}) => {
  const t = useTranslations("Notifications");
  const { currentPortal, openPortal } = usePortalContext();

  /**
   * Хук для получения данных об уведомлениях и обработчиков:
   * - unreadCount — кол-во непрочитанных уведомлений
   */
  const { unreadCount, portalNotifications, loading, closeCurrentPortal } =
    useNotifications();

  /**
   * Флаг наличия непрочитанных уведомлений
   */
  const hasUnreaded = unreadCount > 0;

  const getNotificationIcon = () => {
    if (currentPortal === PORTALS.NotificationSidebar) {
      return "notifications_active";
    }
    if (hasUnreaded) {
      return "notifications_unread";
    }
    return "notifications";
  };

  const onOpenNotifications = () => {
    openPortal(PORTALS.NotificationSidebar);
  };

  const triggerVariants: Record<NotificationsVariant, React.ReactNode> = {
    header: (
      <div className={cn("hide", "md:block")}>
        <InfoBoxV4
          iconLeft={getNotificationIcon()}
          text={hasUnreaded ? unreadCount : ""}
          color="violet"
          onClick={onOpenNotifications}
          aria-label="Open Notifications"
        />
      </div>
    ),
    burger: (
      <>
        <hr />
        <div
          className="flex cursor-pointer items-center gap-1 py-2 text-foreground/70"
          onClick={onOpenNotifications}
        >
          <Text
            text={""}
            fontSize={24}
            color="gray"
            data-icon={getNotificationIcon()}
            className="material-icons"
          />
          <Text
            text={t("title")}
            fontSize={18}
            fontWeight={500}
            className="!text-inherit"
          />
          <Badge
            color="danger"
            isPing
            isInvisible={!hasUnreaded}
            classNames={{ base: "pl-2" }}
          />
        </div>
      </>
    ),
  };

  return (
    <>
      {triggerVariants[variant]}

      {/**
       * Портальные уведомления (тип 1). Для каждого такого уведомления
       * рендерим <NotificationsPortal />.
       * В props передаём: portalId, контент, заголовок и т.д.
       */}
      {portalNotifications?.map(
        (notification) =>
          "content" in notification.props && (
            <NotificationsPortal
              key={notification.id}
              portalId={`notification-${notification.id}`}
              {...notification.props}
              loading={loading}
              onClose={closeCurrentPortal}
            />
          ),
      )}
    </>
  );
};
