"use client";

import { FC } from "react";

import { BurgerSidebar } from "./BurgerSidebar";
import { BurgerTrigger } from "./BurgerTrigger";

export const BurgerMenu: FC<{ promo?: boolean }> = ({ promo }) => (
  <>
    <BurgerTrigger />
    <BurgerSidebar promo={promo} />
  </>
);
