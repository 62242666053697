"use client";

import { GE } from ".constants/gtmEvents";
import { PATH_AUTH, PATH_BLOG, PATH_DEVELOPER } from ".constants/links";
import { PORTALS } from ".constants/portals";
import { useSubscription } from "@apollo/client";
import { Button, InfoBoxV4, usePortalContext } from "@cloai/uikit";
import { useLocale, useTranslations } from "next-intl";
import { FC, HTMLAttributes, MouseEvent, useMemo } from "react";
import { useCookies } from "react-cookie";
import { useMount } from "react-use";

import { REDIRECTION_NOTIFICATION } from "@/.graphql";
import { BurgerMenu } from "@/components/burger-menu";
import { useCompany } from "@/components/hooks/useCompany";
import { useFlag, LanguageCode } from "@/components/hooks/useLanguage";
import { Notifications } from "@/components/notifications";
import { PasskeyButton } from "@/components/PasskeyButton";
import { usePathname, useRouter } from "@/i18n";
import { useProfileContext, useGTMContext } from "@/providers";
import { cn } from "@/utils/cn";

import { HeaderGiftButton } from "./HeaderGiftButton";
import { HeaderLogo } from "./HeaderLogo";
import { HeaderUserBalance } from "./HeaderUserBalance";

type THeaderProps = HTMLAttributes<HTMLHeadElement> & {
  promo?: boolean;
};

export const Header: FC<THeaderProps> = ({ promo, className, ...props }) => {
  const t = useTranslations("Landing");
  const profile = useProfileContext();
  const { openPortal } = usePortalContext();
  const { sendGTM } = useGTMContext();

  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();

  const [cookies] = useCookies([
    "_r",
    "ga_ab",
    "ga_abc",
    "segment_ab",
    "passkeyDontShow",
    "rulesAccepted",
  ]);

  const flag = useFlag();
  const { companyLower } = useCompany();

  useSubscription(REDIRECTION_NOTIFICATION, {
    onData: ({ data: { data } }) => {
      const url = data?.redirectionNotification?.url;

      if (url) {
        // NOTE: ожидаем ссылку типа `${PATH_BUY}/30/payment-failed-visa?methodId=PayWay`
        sendGTM({ event: GE.PAYMENT_FAILED_AND_REDIRECT });
        router.push(url);
      }
    },
  });

  const isShowBalance = profile && !pathname.includes(PATH_DEVELOPER);
  const isShowLangButton =
    !process.env.NEXT_PUBLIC_IS_CN && !pathname.includes(PATH_BLOG);

  const authLink = useMemo(() => {
    if (!companyLower.includes("clothoff.me")) {
      return PATH_AUTH;
    }

    if (cookies._r) {
      return `https://${companyLower}${PATH_AUTH}?utm_source=r&utm_medium=${cookies._r}`;
    }

    return `https://${companyLower}${PATH_AUTH}`;
  }, [cookies._r, companyLower]);

  const openLanguageModal = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    sendGTM({ event: GE.CHOOSE_LANGUAGE_HEADER });
    openPortal(PORTALS.ChangeLang);
  };

  useMount(() => {
    if (profile?.isBlockedUndress) {
      openPortal(PORTALS.BlockUser);
    }
  });

  return (
    <header
      className={cn(
        "relative flex items-center justify-between py-3",
        "lg:py-6",
        className,
      )}
      {...props}
    >
      <HeaderLogo promo={promo} />

      <div className="flex items-center gap-3">
        {!profile && (
          <Button
            href={authLink}
            text={t("signIn")}
            color="regular"
            size="little"
            data-event="chooseweb_header"
            disabled={!cookies.rulesAccepted}
          />
        )}

        {profile && isShowBalance && (
          <>
            <HeaderGiftButton variant="header" />

            <HeaderUserBalance />

            <Notifications variant="header" />
          </>
        )}

        {isShowLangButton && (
          <div className="hide md:block">
            <InfoBoxV4
              iconLeft={flag(locale as LanguageCode)}
              text={locale.toUpperCase()}
              onClick={openLanguageModal}
            />
          </div>
        )}

        {profile && (
          <div className="hide md:block">
            <PasskeyButton variant="header" />
          </div>
        )}

        <BurgerMenu promo={promo} />
      </div>
    </header>
  );
};
