export const internationalFormatNum = ({
  number,
  notation = "compact",
  fraction = 1,
}: {
  number?: number;
  notation?: "compact" | "standard" | "scientific" | "engineering";
  fraction?: number;
}) => {
  if (number) {
    return new Intl.NumberFormat("en", {
      notation,
      maximumFractionDigits: fraction,
    })
      .format(number)
      .toString();
  }
  return "0";
};
