"use client";

import { useMutation, useSubscription } from "@apollo/client";
import { usePortalContext } from "@cloai/uikit";
import { filter, orderBy, some, map } from "lodash";
import { useTranslations } from "next-intl";
import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { useEffectOnce } from "react-use";

import { NotificationsQuery, PaymentNotification } from "@/.gql/graphql";
import {
  GET_NOTIFICATIONS,
  MARK_AS_READ_NOTIFICATION,
  NEW_NOTIFICATION,
} from "@/.graphql";
import { useNotificationsContext } from "@/providers";

import { ToastIcon } from "../ToastIcon";
import { ToastMessage } from "../ToastMessage";

/**
 * Кастомный хук для управления уведомлениями в клиентском окружении (React, Next.js).
 *
 * @remarks
 * - Позволяет подписываться на новые уведомления (через GraphQL-subscription),
 *   автоматически обновляет кэш Apollo Client при их получении.
 * - Предоставляет функционал «прочитать уведомление» (mark as read),
 *   а также «массово» открывать порталы (модальные окна) для уведомлений типа `portal`.
 * - При получении уведомления типа «toast» (type === 0) — отображает всплывающий тост (React-Toastify).
 * - При получении уведомления типа «portal» (type === 1) — открывает портал (модальное окно).
 *
 * @dependencies
 * - **@apollo/client**: используются `useMutation` и `useSubscription` для работы с GraphQL.
 * - **@cloai/uikit**: используется контекст `usePortalContext` для управления порталами.
 * - **lodash**: функции `filter`, `orderBy`, `some`, `map` для работы с массивами уведомлений.
 * - **react-toastify**: для отображения тост-уведомлений.
 * - **react-use**: для хука `useEffectOnce`, который нужен, чтобы автоматически открыть порталы
 *   при первом рендере.
 *
 * @returns Объект со следующими полями:
 * - **toastNotifications**: отсортированный массив уведомлений, предназначенных для тостов (type === 0).
 * - **portalNotifications**: отсортированный массив уведомлений, предназначенных для порталов (type === 1).
 * - **unreadNotificationsIds**: массив `id` непрочитанных уведомлений (readAt === null).
 * - **unreadCount**: Количество непрочитанных уведомлений.
 * - **onRead**: функция для массовой отметки уведомлений как прочитанных. Принимает массив `ids` и отправляет параллельно мутации для каждого `id`.
 * - **closeCurrentPortal**: функция, закрывающая текущий активный портал, при этом проверяет, нужно ли
 *   помечать уведомление как прочитанное.
 * - **loading**: boolean-флаг состояния загрузки для мутации «прочитать уведомление».
 * - **onOpenDetails**: функция, открывающая портал (детальную карточку уведомления) по его `id`.
 *
 * @example
 * ```tsx
 * import { Notifications } from "@/components/Notifications";
 *
 * export default function Page() {
 *   return (
 *     <main>
 *       <Notifications />
 *     </main>
 *   );
 * }
 * ```
 *
 * @see {@link https://react-toastify.com/ | Документация по react-toastify}
 * @see {@link https://www.apollographql.com/docs/react/ | Apollo Client}
 */

export const useNotifications = () => {
  const t = useTranslations("Notifications");
  const { openPortal, closePortal, currentPortal } = usePortalContext();
  const notifications = useNotificationsContext();

  /**
   * Мутация «прочитать уведомление»
   */
  const [markAsReadNotification, { loading }] = useMutation(
    MARK_AS_READ_NOTIFICATION,
  );

  /**
   * Подписка на новые уведомления
   */
  useSubscription(NEW_NOTIFICATION, {
    onData: ({ data: { data }, client }) => {
      if (data?.newNotification) {
        const newNotification = data.newNotification;

        // Проверяем, существует ли уже уведомление с таким id
        const exists = some(notifications, { id: newNotification.id });

        if (!exists) {
          // Обновляем кэш вручную только если уведомление новое
          client.writeQuery({
            query: GET_NOTIFICATIONS,
            data: {
              getNotifications: [...notifications, newNotification],
            },
          });

          // Обрабатываем данные и вызываем тостеры
          handleToast(newNotification);
        } else {
          console.log(`Уведомление с id ${newNotification.id} уже существует.`);
        }
      }
    },
  });

  const handleToast = useCallback(
    (newNotification: NotificationsQuery["getNotifications"][0]) => {
      // Если уведомление "toast" (type=0), показываем всплывающий тост
      if (newNotification.type === 0) {
        // Если это PaymentNotification, используем coins и typeCoins
        if (newNotification.props.__typename === "PaymentNotification") {
          const props = newNotification.props as PaymentNotification;
          toast.success(
            <ToastMessage
              title={t(`toastBalance.${props.title}` as never)}
              text={t.rich("toastBalance.text", {
                coins: props.coins,
                type: props.typeCoins,
              })}
            />,
            {
              icon: () => ToastIcon(props.typeCoins),
              toastId: newNotification.id,
            },
          );
        }

        // Если это DefaultNotification, используем text и title
        if (newNotification.props.__typename === "DefaultNotification") {
          toast.info(
            <ToastMessage
              title={newNotification.props.title}
              text={newNotification.props.text || ""}
            />,
            {
              icon: ({ type }) => ToastIcon(type),
              toastId: newNotification.id,
            },
          );
        }
      } else {
        // Иначе открываем портал (модальное окно)
        openPortal(`notification-${newNotification.id}`);
      }
    },
    [openPortal, t],
  );

  // Массив непрочитанных
  const unreadNotifications = useMemo(
    () => filter(notifications, { readAt: null }),
    [notifications],
  );

  // Массив ids непрочитанных
  const unreadNotificationsIds = useMemo(
    () => map(unreadNotifications, "id"),
    [unreadNotifications],
  );

  // Количество непрочитанных уведомлений
  const unreadCount = unreadNotifications.length;

  // Уведомления "toast" (type === 0) - сортируем по дате в порядке убывания
  const toastNotifications = useMemo(
    () =>
      orderBy(filter(notifications, { type: 0 }), (n) => n.createdAt, "desc"),
    [notifications],
  );

  // Уведомления "portal" (type === 1)
  const portalNotifications = useMemo(
    () =>
      orderBy(filter(notifications, { type: 1 }), (n) => n.createdAt, "desc"),
    [notifications],
  );

  /**
   * onRead: функция для массовой отметки уведомлений как прочитанных.
   * Принимает массив `ids` и отправляет параллельно мутации для каждого `id`.
   */
  const onRead = useCallback(
    (ids: string[]) => async () => {
      if (!ids.length) {
        return;
      }

      try {
        // Создаем массив промисов для каждой мутации
        const mutationPromises = ids.map((id) =>
          markAsReadNotification({ variables: { notifId: id } }).catch(
            (error) => {
              console.error(
                `Ошибка при отметке уведомления ${id} как прочитанного:`,
                error,
              );
              // Возвращаем null или другой индикатор ошибки для данного id
              return null;
            },
          ),
        );

        // Выполняем все мутации параллельно
        const results = await Promise.all(mutationPromises);

        // Фильтруем успешные мутации (если необходимо)
        const successfulReads = results.filter((result) => result !== null);

        // Можно добавить дополнительную логику после успешных мутаций
        console.log(
          `${successfulReads.length} уведомлений успешно отмечены как прочитанные.`,
        );
      } catch (error) {
        // Обработка общей ошибки (не должна произойти, так как индивидуальные ошибки уже обрабатываются)
        console.error("Ошибка при массовой отметке уведомлений:", error);
      }
    },
    [markAsReadNotification],
  );

  /**
   * Закрыть текущий портал, пометив уведомление «прочитанным» (если оно непрочитанное)
   */
  const closeCurrentPortal = useCallback(async () => {
    const portalId = currentPortal?.replace("notification-", "") ?? "";
    if (currentPortal && some(unreadNotifications, ["id", portalId])) {
      await markAsReadNotification({
        variables: { notifId: portalId },
      }).catch(() => false);
    }
    closePortal();
  }, [closePortal, currentPortal, markAsReadNotification, unreadNotifications]);

  /**
   * Показать все непрочитанные уведомления типа portal при первом рендере
   */
  const showNotificationPortals = useCallback(() => {
    const _portalNotifications = filter(unreadNotifications, { type: 1 });
    const ids = map(_portalNotifications, (n) => `notification-${n.id}`);
    // Можно открыть несколько порталов за раз
    openPortal(ids);
  }, [unreadNotifications, openPortal]);

  // При первом рендере показываем все порталы типа portal, которые не прочитаны
  useEffectOnce(() => {
    showNotificationPortals();
  });

  /**
   * Открыть детальную карточку уведомления
   */
  const onOpenDetails = useCallback(
    (id: string) => {
      openPortal(id);
    },
    [openPortal],
  );

  return {
    toastNotifications,
    portalNotifications,
    unreadNotificationsIds,
    unreadCount,
    onRead,
    closeCurrentPortal,
    loading,
    onOpenDetails,
  };
};
