import {
  PortalModal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Button,
} from "@cloai/uikit";
import { FC } from "react";

import { PortalNotification } from "@/.gql/graphql";

interface INotificationsPortalProps extends PortalNotification {
  portalId: string;
  loading?: boolean;
  onClose: () => void;
}

/**
 * Компонент, отвечающий за отображение уведомления в портале (модальном окне).
 *
 * @remarks
 * - Использует `PortalModal` из библиотеки `@cloai/uikit`, который рендерится
 *   в портале с идентификатором `portalId`.
 * - Поддерживает передачу заголовка (`title`) и содержимого (`content`) уведомления.
 *   Содержимое выводится через `dangerouslySetInnerHTML` (HTML-верстка).
 * - Предоставляет кнопку закрытия, которая вызывает колбэк `onClose`.
 *
 * @property {string} portalId - Идентификатор портала, в который будет монтироваться модальное окно.
 * @property {string} [title] - Заголовок уведомления. Если не передан, заголовок не отображается.
 * @property {string} content - HTML-содержимое уведомления. Выводится в модальном окне через `dangerouslySetInnerHTML`.
 * @property {boolean} [loading] - Опциональный флаг загрузки (например, для блокировки кнопки во время выполнения запроса).
 * @property {() => void} onClose - Колбэк, вызываемый при закрытии модального окна (например, клик по кнопке «Close»).
 *
 * @example
 * ```tsx
 * import { NotificationsPortal } from "./NotificationsPortal";
 *
 * export function App() {
 *   const [loading, setLoading] = useState(false);
 *
 *   const handleClosePortal = () => {
 *     // Допустим, выполняем какую-то логику при закрытии
 *     console.log("Портал закрыт");
 *   };
 *
 *   return (
 *     <NotificationsPortal
 *       portalId="notification-123"
 *       title="Ваше уведомление"
 *       content="<p>Содержимое уведомления с HTML-разметкой</p>"
 *       loading={loading}
 *       onClose={handleClosePortal}
 *     />
 *   );
 * }
 * ```
 */

export const NotificationsPortal: FC<INotificationsPortalProps> = ({
  portalId,
  title,
  content,
  loading,
  onClose,
}) => (
  <PortalModal portalId={portalId} variant="fixed" onClose={onClose}>
    {title && <ModalHeader>{title}</ModalHeader>}
    <ModalContent
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
    <ModalFooter>
      <Button
        text="Close"
        size="large"
        wide
        centerText
        onClick={onClose}
        loading={loading}
      />
    </ModalFooter>
  </PortalModal>
);
