"use client";

import { GE } from ".constants/gtmEvents";
import { PATH_BUY, PATH_EARN } from ".constants/links";
import undressConfig from ".constants/undress.config.json";
import { Button, Text } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC } from "react";

import { useGTMContext, useProfileContext } from "@/providers";
import { cn } from "@/utils/cn";
import { internationalFormatNum } from "@/utils/internationalFormatNum";

export const BurgerUserBalance: FC = () => {
  const t = useTranslations("HomePage");
  const profile = useProfileContext();
  const { onSendGTM } = useGTMContext();

  const noVipCoins = !profile || profile.coins < undressConfig.undressCost;

  return (
    <div className="flex w-full flex-col items-start gap-1">
      <div className="flex w-full items-center justify-between py-1">
        <div className="flex items-center justify-center gap-1">
          <Text
            text=""
            className="material-icons"
            data-icon="diamond"
            fontSize={24}
            lineHeight={100}
            color="orange"
          />
          <Text
            text={internationalFormatNum({
              number: profile?.coins,
              notation: "standard",
            })}
            fontSize={16}
            fontWeight={500}
            color="white"
            className={cn(!profile?.coins && "opacity-72")}
          />
        </div>

        <Button
          href={PATH_BUY}
          iconLeft="add"
          text={t("vipCoins")}
          color={noVipCoins ? "orange" : "regular"}
          size="tiny"
          onClick={onSendGTM(GE.BURGER_BUY_VIP_COIN)}
        />
      </div>

      <div className="flex w-full items-center justify-between py-1">
        <div className="flex items-center justify-center gap-1">
          <Text
            text=""
            className="material-icons"
            data-icon="star"
            fontSize={24}
            lineHeight={100}
            color="violet"
          />
          <Text
            text={internationalFormatNum({
              number: profile?.freeCoins,
              notation: "standard",
            })}
            fontSize={16}
            fontWeight={500}
            color="white"
            href={PATH_EARN}
            className={cn(!profile?.freeCoins && "opacity-72")}
          />
        </div>

        <Button
          href={PATH_EARN}
          iconLeft="add"
          text={t("freeCoins")}
          color="regular"
          size="tiny"
          onClick={onSendGTM(GE.BURGER_EARN_COINS_BUTTON)}
        />
      </div>
    </div>
  );
};
