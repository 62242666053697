"use client";

/**
 * Компонент HeaderGiftButton отвечает за отображение кнопки подарка в шапке приложения.
 *
 * @remarks
 * Функциональность:
 * - Отображает иконку подарка.
 * - Если доступен подарок для получения (`hasToClaimGift` = true), показывает бейдж "1".
 * - При клике на кнопку открывает портал с дорожной картой подарков (GiftRoadmap).
 *
 * Взаимодействие с контекстом и данными:
 * - Использует `useGiftContext` для получения:
 *   - `hasToClaimGift`: флаг, указывающий на наличие доступного подарка.
 *   - `giftsList`: список всех подарков.
 *   - `timeToNextGift`: время до следующего подарка.
 *   - `onResetInterval`: функцию сброса интервала при окончании таймера.
 * - Использует `usePortalContext` для открытия портала.
 * - Использует `useTimer` для отсчёта времени до следующего подарка.
 * - Подписывается на обновления (`useSubscription`) от GraphQL (UPDATE_BONUS) для актуализации данных о подарках.
 *
 * Логика:
 * - Если `time` возвращает `null` после истечения таймера, вызывается `onResetInterval` для обновления состояния.
 * - Если подарков нет (`giftsList` пуст), кнопка не отображается.
 * - При клике на кнопку отправляется событие в GTM (`gift_button_header`) и открывается модальное окно с подарками.
 *
 * @returns JSX-элемент с кнопкой подарка или `null`, если подарков нет.
 */

import { PORTALS } from ".constants/portals";
import { useSubscription } from "@apollo/client";
import {
  Badge,
  InfoBoxV4,
  Text,
  usePortalContext,
  useTimer,
} from "@cloai/uikit";
import { size } from "lodash";
import Image from "next/image";
import { ReactNode, useEffect, useMemo } from "react";

import { UPDATE_BONUS } from "@/.graphql";
import { useGiftContext, useGTMContext } from "@/providers";
import GiftIcon from "@/styles/image/gift-icon.webp";

type HeaderGiftButtonVariant = "burger" | "header";

type HeaderGiftButtonProps = {
  variant: HeaderGiftButtonVariant;
  text?: string | ReactNode;
};

/** Компонент HeaderGiftButton отвечает за отображение кнопки подарка в шапке приложения. */
export const HeaderGiftButton = ({ variant, text }: HeaderGiftButtonProps) => {
  const { hasToClaimGift, giftsList, timeToNextGift, onResetInterval } =
    useGiftContext();
  const { openPortal } = usePortalContext();
  const { sendGTM } = useGTMContext();

  const time = useTimer(timeToNextGift);
  useSubscription(UPDATE_BONUS);

  const showIndicator = useMemo(
    () => hasToClaimGift && giftsList.length > 0,
    [giftsList.length, hasToClaimGift],
  );

  /**
   * Проверяем, если время истекло (`time === null`), сбрасываем интервал для обновления состояния.
   * Обязательно нужно проверять на `null`, так как `useTimer` возвращает `undefined` при первом рендере.
   */
  useEffect(() => {
    if (time === null && size(giftsList) !== 0) {
      onResetInterval();
    }
  }, [time, onResetInterval, giftsList]);

  /** Обработчик клика на кнопку подарка */
  const onGift = () => {
    sendGTM({ event: `gift_button_${variant}` });
    openPortal(PORTALS.GiftRoadmap);
  };

  /** Если подарков нет, не отображаем кнопку */
  if (size(giftsList) === 0) {
    return null;
  }

  const variants: Record<HeaderGiftButtonVariant, ReactNode> = {
    header: (
      <div className="hide md:block">
        <InfoBoxV4
          iconLeft={GiftIcon}
          text={hasToClaimGift ? 1 : undefined}
          color="violet"
          onClick={onGift}
        />
      </div>
    ),
    burger: (
      <div
        className="flex cursor-pointer items-center gap-1 py-3 text-foreground/70"
        onClick={onGift}
      >
        <Image src={GiftIcon} alt="server icon" width={22} height={22} />

        <Text
          text={text || ""}
          fontSize={18}
          fontWeight={500}
          className="!text-inherit"
        />
        <Badge
          color="danger"
          isPing
          isInvisible={!showIndicator}
          classNames={{ base: "pl-2" }}
        />
      </div>
    ),
  };

  return variants[variant];
};
