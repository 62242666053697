"use client";

import { GE } from ".constants/gtmEvents";
import {
  PATH_DEVELOPER,
  PATH_MAKE_MONEY,
  SOCIAL_CHANNEL_TWITTER,
} from ".constants/links";
import { PORTALS } from ".constants/portals";
import {
  Button,
  ModalContent,
  PortalModal,
  Text,
  usePortalContext,
} from "@cloai/uikit";
import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import { CSSProperties, FC, useEffect, useRef } from "react";

import { HeaderGiftButton } from "@/components/header/HeaderGiftButton";
import { useCompany } from "@/components/hooks/useCompany";
import {
  LanguageCode,
  useFlag,
  useLanguage,
} from "@/components/hooks/useLanguage";
import { useSupport } from "@/components/hooks/useSupport";
import { Notifications } from "@/components/notifications";
import { PasskeyButton } from "@/components/PasskeyButton";
import { SidebarHeader } from "@/components/sidebar/SidebarHeader";
import { usePathname } from "@/i18n";
import { useGTMContext, useProfileContext } from "@/providers";
import IconX from "@/styles/image/social-buttons/twitter-x.svg";
import { getGTM } from "@/utils/gtm";

import { BurgerUserBalance } from "./BurgerUserBalance";

export type BurgerSidebarProps = {
  promo?: boolean;
};

export const BurgerSidebar: FC<BurgerSidebarProps> = ({ promo }) => {
  const h = useTranslations("HomePage");
  const f = useTranslations("Footer");

  const profile = useProfileContext();
  const { currentPortal, openPortal, closePortal } = usePortalContext();
  const { sendGTM } = useGTMContext();

  const { companyLower, isDeepnudeNET, isOkbra } = useCompany();
  const onClickSupport = useSupport();
  const locale = useLocale() as LanguageCode;
  const flag = useFlag();
  const currentLanguage = useLanguage();
  const pathname = usePathname();

  const GTM = getGTM(companyLower);

  const isShowBalance = profile && !pathname.includes(PATH_DEVELOPER);

  const onLanguageModal = () => {
    sendGTM({ event: GE.CHOOSE_LANGUAGE_BURGER });
    openPortal(PORTALS.ChangeLang);
  };

  // Закрытие меню при клике на любой элемент внутри него
  const burgerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (burgerRef.current?.contains(event.target as Node)) {
        closePortal(); // Закрываем меню, если клик был внутри
      }
    };

    if (currentPortal === PORTALS.BurgerMenu) {
      document.addEventListener("click", handleClick);
    }

    return () => document.removeEventListener("click", handleClick);
  }, [closePortal, currentPortal]);

  return (
    <>
      <PortalModal
        portalId={PORTALS.BurgerMenu}
        onClose={closePortal}
        variant="right"
        style={
          {
            "--color-popup": "var(--color-body)",
          } as CSSProperties
        }
      >
        <div ref={burgerRef} className="flex h-full flex-col gap-popup-gap">
          <SidebarHeader promo={promo} />

          <ModalContent className="gap-3">
            {isShowBalance && (
              <>
                <BurgerUserBalance />
                <hr />
              </>
            )}

            <HeaderGiftButton variant="burger" text={h("dailyBonus")} />

            <Notifications variant="burger" />

            <hr />

            <Link
              href={PATH_MAKE_MONEY}
              className="flex w-full items-center justify-between py-3 text-foreground/70"
            >
              <Text
                text={f("makeMoney")}
                className="text-inherit!"
                fontWeight={500}
                fontSize={18}
              />
              <span
                data-icon={"chevron_right"}
                className="material-icons text-inherit"
              />
            </Link>

            {((!isDeepnudeNET && GTM) || isOkbra) && (
              <div
                onClick={onClickSupport}
                className="flex w-full cursor-pointer items-center justify-between py-3 text-foreground/70"
              >
                <Text
                  text={f("support")}
                  className="text-inherit!"
                  fontWeight={500}
                  fontSize={18}
                />
                <span
                  data-icon={"support"}
                  className="material-icons text-inherit"
                />
              </div>
            )}

            <hr />

            <Button
              iconLeft={flag(locale)}
              text={currentLanguage}
              iconRight="arrow_drop_down"
              color="outline"
              centerText
              wide
              onClick={onLanguageModal}
            />

            {profile && <PasskeyButton variant="burger" />}

            <div className="flex items-center justify-center gap-3">
              <Link
                href={SOCIAL_CHANNEL_TWITTER(companyLower)}
                target="_blank"
                className="flex size-12 items-center justify-center rounded-2xl border border-regular p-1 outline-none"
                aria-label="twitter"
              >
                <Image src={IconX} alt="X" width={32} height={32} />
              </Link>
            </div>
          </ModalContent>
        </div>
      </PortalModal>
    </>
  );
};
