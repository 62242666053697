"use client";

import { PORTALS } from ".constants/portals";
import { Badge, Icon, usePortalContext } from "@cloai/uikit";
import { FC, useMemo } from "react";

import { useGiftContext } from "@/providers";

export const BurgerTrigger: FC = () => {
  const { hasToClaimGift, giftsList } = useGiftContext();
  const { openPortal } = usePortalContext();

  const showIndicator = useMemo(
    () => hasToClaimGift && giftsList.length > 0,
    [giftsList.length, hasToClaimGift],
  );

  const onOpenBurgerMenu = () => {
    openPortal(PORTALS.BurgerMenu);
  };

  return (
    <Badge
      color="danger"
      shape="circle"
      isPing
      isInvisible={!showIndicator}
      classNames={{ base: "md:hide" }}
    >
      <Icon icon="menu" size={8} color="secondary" onClick={onOpenBurgerMenu} />
    </Badge>
  );
};
