import { SyntheticEvent, useCallback } from "react";

import { useCompany } from "./useCompany";

export const useSupport = () => {
  const { isOkbra } = useCompany();

  const jivoOnLoadCallback = useCallback(() => {
    const jdiv = document.querySelector("jdiv");

    if (window.jivo_api && jdiv) {
      jdiv.className = "active";
      window.jivo_api.open();
    }

    delete window.jivo_onLoadCallback;
  }, []);

  const onClickSupport = (event: SyntheticEvent) => {
    if (event) {
      event.preventDefault();
    }

    if (isOkbra) {
      return window.open("https://t.me/okbraabot", "_ blank");
    }

    window.jivo_onLoadCallback = jivoOnLoadCallback;

    setTimeout(() => {
      window.jivo_init();
    }, 100);
  };

  return onClickSupport;
};
