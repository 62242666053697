"use client";

import { GE } from ".constants/gtmEvents";
import { PATH_BUY, PATH_EARN } from ".constants/links";
import undressConfig from ".constants/undress.config.json";
import { InfoBoxV3, InfoBoxV4, Text } from "@cloai/uikit";
import { Route } from "next";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { FC } from "react";

import { useGTMContext, useProfileContext } from "@/providers";
import { cn } from "@/utils/cn";
import { internationalFormatNum } from "@/utils/internationalFormatNum";

export const HeaderUserBalance: FC = () => {
  const i = useTranslations("HomePage");
  const profile = useProfileContext();
  const { onSendGTM } = useGTMContext();

  const freeCoins = profile?.freeCoins?.toString() || "0";
  const vipCoins = profile?.coins?.toString() || "0";
  const freeCoinsFormatted = internationalFormatNum({
    number: profile?.freeCoins,
  });
  const vipCoinsFormatted = internationalFormatNum({ number: profile?.coins });
  const noVipCoins = !profile || profile.coins < undressConfig.undressCost;

  return (
    <>
      <div className={cn("hide", "md:!flex md:gap-1")}>
        <InfoBoxV4
          href={PATH_EARN}
          iconLeft="star"
          text={freeCoinsFormatted}
          color="violet"
          onClick={onSendGTM(GE.HEADER_EARN_COINS_BUTTON)}
          title={`${i("freeCoin")} ${freeCoins}`}
        />

        {noVipCoins ? (
          <InfoBoxV3
            href={PATH_BUY}
            scroll={false}
            iconLeft="diamond"
            iconRight="add"
            color="gold"
            text={vipCoinsFormatted}
            onClick={onSendGTM(GE.HEADER_BUY_VIP_COIN)}
            title={`${i("vipCoin")} ${vipCoins}`}
          />
        ) : (
          <InfoBoxV4
            href={PATH_BUY}
            scroll={false}
            iconLeft="diamond"
            iconRight="add"
            color={"orange"}
            text={vipCoinsFormatted}
            onClick={onSendGTM(GE.HEADER_BUY_VIP_COIN)}
            title={`${i("vipCoin")} ${vipCoins}`}
          />
        )}
      </div>

      <Link
        href={PATH_BUY as Route}
        className={cn(
          "relative z-0 flex items-center justify-between gap-2 rounded-lg bg-regular py-1.5 pr-2.5 pl-2",
          "md:hide",
          noVipCoins && [
            "bg-transparent duration-200",
            "hover:shadow-[0_0_16px_4px_#ff722359,_0_0_18px_4px_#ff722359_inset]",
            "before:absolute before:inset-0 before:-z-2 before:rounded-lg before:bg-primary before:bg-[linear-gradient(135deg,rgba(255,255,255,0.5)_0%,rgba(255,255,255,0)_34%,#FFF_56.92%,rgba(255,255,255,0)_61.56%,rgba(255,255,255,0.5)_100%)]",
            "after:absolute after:inset-0.5 after:-z-1 after:rounded-lg after:bg-body",
          ],
        )}
        onClick={onSendGTM(GE.HEADER_BUY_VIP_COIN)}
      >
        <div className="flex shrink-0 flex-col items-start justify-center">
          <div className="flex items-center justify-center gap-1">
            <Text
              text=""
              className="material-icons"
              data-icon="diamond"
              fontSize={16}
              lineHeight={100}
              color="orange"
            />
            <Text
              text={vipCoinsFormatted}
              fontSize={14}
              fontWeight={500}
              lineHeight={100}
              color="white"
              className={cn(!profile?.coins && "opacity-72")}
            />
          </div>
          <div className="flex items-center justify-center gap-1">
            <Text
              text=""
              className="material-icons"
              data-icon="star"
              fontSize={16}
              lineHeight={100}
              color="violet"
            />
            <Text
              text={freeCoinsFormatted}
              fontSize={14}
              fontWeight={500}
              lineHeight={100}
              color="white"
              href={PATH_EARN}
              className={cn(!profile?.freeCoins && "opacity-72")}
            />
          </div>
        </div>

        <div
          className={cn(
            "material-icons flex size-6 items-center justify-center rounded-md bg-secondary",
            noVipCoins &&
              "bg-primary bg-[linear-gradient(135deg,rgba(255,255,255,0.5)_0%,rgba(255,255,255,0)_34%,#FFF_56.92%,rgba(255,255,255,0)_61.56%,rgba(255,255,255,0.5)_100%)] text-body",
          )}
          data-icon="add"
        />
      </Link>
    </>
  );
};
